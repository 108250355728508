.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#homePageBanner{
  height: 91vh;
  width: 100vw;
  background-image: linear-gradient(24deg,rgba(200, 255, 255, .6) 4%, rgba(71, 15, 201, .8) 100%),url(./resources/images/people-working-elegant-cozy-office-space.jpg);
  background-size: cover;
  background-position: center;
  max-width: 100%;
  min-height: 100%;
}
/* #startLink{
  
} */
.formStyles{
  background-image: linear-gradient(24deg,rgba(200, 255, 255, .6) 4%, rgba(71, 15, 201, .8) 100%);
  width: 54rem;
}
.formPagesBackgroundPattern{
  background-image: linear-gradient(45deg, rgba(255,255,255,.6),rgba(255,255,255,.6)), url("./resources/images/lightGrayPattern.jpg");
  background-size: cover;
  background-repeat: repeat-y;
  min-height: 100vh;
  height: auto;
}

.NameProjectList{
  background-image: linear-gradient(96deg,rgba(200, 255, 255, .6) 4%, rgba(71, 15, 201, .8) 100%);
  
}

.myBtn{
  background-color:rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
}
.myCarouselBtn{
  background-color:rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
  --bs-btn-padding-y:.1rem;
  --bs-btn-padding-x: .1rem;
  --bs-btn-font-size: .01rem;

}
.swal-button{
  background-color:rgba(71, 15, 201, .8);
  border-color: rgba(71, 15, 201, .8);
}
.myActive{
  visibility: visible;

}
.notActive{
  visibility: hidden;
}
.selected{
  background-color: rgb(128, 195, 233);
  color: white;
}
.notSelected{
  background-color: white;
  color: black;
}
.bi::before {
  display: inline-block;
  content: "";
  vertical-align: -.125em;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}
.languageIco{
  width: 40%;
}